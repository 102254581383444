import React from 'react';
import {StaticImage} from "gatsby-plugin-image";

function NewsSimple() {
    return (
        <div className="portfolio-related-work ptb--120" id="news">
            <div className="about-wrapper p-4 p-md-0">
                <div className="container">
                    <div className="row row--35">
                        <div className="col-lg-12">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <span className="primary-subtitle">Novice in obvestila</span>
                                    <h2 className="title">Aktualne novice iz litijskega podzemlja</h2>
                                </div>
                                <div className="row mt--30">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="single-tab-content mt-4">
                                                    <div className="row">
                                                        <div className="col-md-7 pr-5 pt-4">

                                                            <p className="text-primary text-uppercase mb--0 fontWeight400" style={{"fontSize": "0.875rem"}}>
                                                                Valvazorjev trg / Rudnik Sitarjevec Litija, nedelja, 3. december 2023, 18:00,
                                                            </p>

                                                            <h3>
                                                                NOČNI POHOD Z BAKLAMI DO RUDNIKA SITARJEVEC 2023
                                                            </h3>

                                                            <p className="mb-15px fontWeight600">
                                                                Ob 18. uri se bomo iz starega mestnega jedra pohodniki z baklami v roki odpravili do Rudnika
                                                                Sitarjevec Litija.
                                                            </p>

                                                            <p className="mb-15px">
                                                                Na ta dan bo obisk Izvoznega rova rudnika brezplačen. Pred pohodom boste
                                                                pohodniki na stojnici TIC Litija prevzeli zaporedno številko vstopa v rudnik, prevzeli bakle in si jih tudi
                                                                prižgali. Vstopi skupin v rudnik se bodo vrstili z 10 minutnim zamikom. Dogajanje v Izvoznem rovu
                                                                bodo popestrili škratje Sitarjevčki. Pohoda se udeležite na lastno odgovornost.
                                                            </p>
                                                        </div>

                                                        <div className="col-md-5 flex justify-content-start align-items-start mt-3">
                                                            <div className="thumb hover-slide-up">
                                                                <StaticImage
                                                                    src="../../assets/images/rudnik/pohod-z-baklami-2023.jpg"
                                                                    alt="S Škratom po rudniku Sitarjevec"
                                                                    placeholder="blurred"
                                                                    imgClassName="img-fluid w-100"
                                                                    loading="lazy"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="single-tab-content mt-4">
                                                    <div className="row">
                                                        <div className="col-md-7 pr-5 pt-4">

                                                            <p className="text-primary text-uppercase mb--0 fontWeight400" style={{"fontSize": "0.875rem"}}>
                                                                Rudnik Sitarjevec Litija, od 26. do 30. decembra 2023, 16:00 – 19:00
                                                            </p>

                                                            <h3>
                                                                MINERALNE JASLICE V RUDNIKU SITARJEVEC LITIJA 2023
                                                            </h3>

                                                            <p className="mb-15px">
                                                                V tednu med Božičem in novim letom vas vabimo pred in v Rudnik Sitarjevec Litija, ki je eden
                                                                najbogatejših mineralnih rudnikov v Evropi.
                                                            </p>

                                                            <p className="mb-15px fontWeight600">
                                                                Obiskovalci si boste v teh dneh brezplačno ogledali
                                                                mineralne jaslice v Izvoznem rovu. Ogledi v skrajšani obliki bodo potekali med 16:00 in 19:00.
                                                                Ogled Glavnega rova rudnika, pa ob predhodni najavi mogoč s 30 procentnim prazničnim
                                                                popustom. Najava na ogled Glavnega rova na info@visitlitija.si, 051 312 739 ali 070 700 484 (TIC
                                                                Litija).
                                                            </p>
                                                            <p>
                                                                Od Božiča pa vse do svečnice bodo v rudniku razstavljene edinstvene jaslice iz kamnin in mineralov rudnika Sitarjevec. Postavitelja letošnjih mineralnih jaslic bosta Andrej Hostnik in Tone Klinc.
                                                            </p>
                                                        </div>

                                                        <div className="col-md-5 flex justify-content-start align-items-start mt-3">
                                                            <div className="thumb hover-slide-up">
                                                                <StaticImage
                                                                    src="../../assets/images/rudnik/mineralne-jaslice.jpeg"
                                                                    alt="S Škratom po rudniku Sitarjevec"
                                                                    placeholder="blurred"
                                                                    imgClassName="img-fluid w-100"
                                                                    loading="lazy"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="single-tab-content mt-4">
                                                    <div className="row">
                                                        <div className="col-md-7 pr-5 pt-4">

                                                            <p className="text-primary text-uppercase mb--0 fontWeight400" style={{"fontSize": "0.875rem"}}>
                                                                Rudnik Sitarjevec Litija, četrtek, 28. 12. 2023 ob 18:00 in 19:00 in petek, 29. 12. 2023 ob 18:00 in 19:00
                                                            </p>

                                                            <h3>
                                                                KONCERTA V RUDNIKU SITARJEVEC LITIJA 2023
                                                            </h3>

                                                            <p className="mb-15px">
                                                                Brezplačne oglede jaslic v rudniku bosta v četrtek, 28. 12 ob 18:00 in 19:00 s koncertom popestrila
                                                                Anže Kristan in Luka Vizlar - PIPALKAR.
                                                            </p>

                                                            <p className="mb-15px">
                                                                Brezplačne oglede jaslic v rudniku bo v petek, 29. 12 ob 18:00 in 19:00 s koncertom božičnih pesmi
                                                                popestril MOŠKI PEVSKI ZBOR POLŠNIK.
                                                            </p>
                                                        </div>
                                                        <div className="col-md-5 flex justify-content-start align-items-start mt-3">
                                                            <div className="flex justify-content-start align-items-start mt-3">
                                                                <div className="thumb hover-slide-up">
                                                                    <StaticImage
                                                                        src="../../assets/images/rudnik/koncert-2023-1.jpg"
                                                                        alt="S Škratom po rudniku Sitarjevec"
                                                                        placeholder="blurred"
                                                                        imgClassName="img-fluid w-100"
                                                                        loading="lazy"
                                                                    />
                                                                </div>
                                                                <div className="thumb hover-slide-up">
                                                                    <StaticImage
                                                                        src="../../assets/images/rudnik/koncert-2023-2.jpg"
                                                                        alt="S Škratom po rudniku Sitarjevec"
                                                                        placeholder="blurred"
                                                                        imgClassName="img-fluid w-100"
                                                                        loading="lazy"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="single-tab-content mt-4">
                                                    <div className="row">
                                                        <div className="col-md-7 pr-5 pt-4">

                                                            <p className="text-primary text-uppercase mb--0 fontWeight400" style={{"fontSize": "0.875rem"}}>
                                                                PRED RUDNIKOM SITARJEVEC, torek, 26. 12. 2023 ob 17. uri
                                                            </p>

                                                            <h3>
                                                                ANIMIRANE JASLICE PRED RUDNIKOM SITARJEVEC 2023
                                                            </h3>

                                                            <p className="mb-15px">
                                                                Na praznični torek, 26. 12. ob 17. uri vas vabimo, da se iz Litije ali iz pakirišča pri Zdravstvenem domu
                                                                Litija peš podate do Rudnika Sitarjevec Litija in <span className="fontWeight600"> prisluhnite božični zgodbi animiranih jaslic v pesku v
                                            izvedbi Irene Režek ob spremstvu ŽVS Lipa Litija. </span> Pred in po uprizoritvi božične zgodbe je možen
                                                                brezplačen ogled Izvoznega rova rudnika.
                                                            </p>

                                                        </div>

                                                        <div className="col-md-5 flex justify-content-start align-items-start mt-3">
                                                            <div className="thumb hover-slide-up">
                                                                <StaticImage
                                                                    src="../../assets/images/rudnik/animirane-jaslice-2023.jpg"
                                                                    alt="S Škratom po rudniku Sitarjevec"
                                                                    placeholder="blurred"
                                                                    imgClassName="img-fluid w-100"
                                                                    loading="lazy"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsSimple;